import React from "react"
import styles from "./email-checklist.module.scss"
import SubscribeOverlayService from "../../../../services/subscribe-overlay-service"
import ScrollAnimation from "react-animate-on-scroll"

const EmailChecklist = ({ customerType }) => (
  <div className={styles.emailChecklist}>
    <div className={`container ${styles.emailContainer}`}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          Увеличьте прибыль вашего <br/>
          {customerType || "детского центра"} <span className={styles.underline}
                                                    onClick={() => SubscribeOverlayService.open()}>всего за 15 шагов</span>
        </div>
        <button className={styles.cta} onClick={() => SubscribeOverlayService.open()}>Скачать чек-лист</button>
        <ScrollAnimation animateIn={"animate-shake"} duration={1}>
          <div className={styles.arrow}/>
        </ScrollAnimation>
      </div>
    </div>
  </div>
)

export default EmailChecklist
