import React, { useState } from "react"
import styles from "./accordion.module.scss"
import AnimateHeight from "react-animate-height"

const data = [
  {
    name: "about",
    title: "Что такое CRM-система для детского и учебного центра?",
    content: `CRM-система для детского, учебного или образовательного центра - это специализированная система для учета клиентов, посещений и абонементов. Она включает в себя инструменты для управления детским центром и ничего лишнего: расписание занятий, профиль ученика и педагога, автоматический расчет зарплаты педагогов, отчеты, онлайн запись клиентов и автоматические уведомления.`,
  },
  {
    name: "use",
    title: "Как внедрить CRM в работу образовательного центра?",
    content: `Если вы прошли первый этап и уже остановили свой выбор на конкретной CRM-системе, то самое главное - научить сотрудников работать в ней. Иногда система может идеально подходить для вашего учета, но из-за недопонимания администраторов и педагогов принципов работы программы, внедрение останавливается. Команда Параплан CRM рекомендует использовать подробный чек-лист для первого заполнения системы, видео и текстовые инструкции из Базы знаний, а также пройти бесплатное онлайн обучение по работе в системе. Во время презентации менеджер Параплана ответит на все вопросы, вызвавшие трудности при внедрении CRM в детский центр.`,
  },
  {
    name: "features",
    title: "Какие основные возможности CRM для учета клиентов в образовательном центре для детей и взрослых?",
    content: `CRM-система берет на себя множество бизнес-процессов компании: ведение базы учеников; привлечение новых клиентов с помощью форм сбора контактов для сайта и онлайн-расписания; удержание действующих клиентов через автоматические рассылки с напоминанием о занятии или об окончании абонемента; учет работы с лидами в воронке продаж; подробная аналитика по показателям учебного центра. Кроме работы с клиентами CRM-система помогает контролировать сотрудников. Вы сможете отслеживать выполнение задач в планировщике, прослушивать телефонные разговоры и читать переписки в мессенджерах, не покидая систему.`,
  },
]
const descr = "<p>Нужна ли детскому или учебному центру CRM-система? Мы в Параплан CRM уверены, что да! Контролировать " +
  "бизнес-процессы в режиме онлайн и автоматизировать рутинные задачи крайне важно. И мы расскажем, как сделать " +
  "быстрый подбор CRM для детского центра. </p><p>В первую очередь рассмотрите специальные системы для дополнительного " +
  "образования. Благодаря таким функциям, как абонементная система, зарплатные ставки педагогов, статусы посещений " +
  "учеников, Личный кабинет ученика и педагога, этот тип CRM позволяет сделать рабочий процесс эффективным и " +
  "организованным. Вам не требуется большое количество разрозненных документов. В единой системе учета клиентов вы " +
  "сможете объединить все потребности бизнеса.  </p><p>Далее активно тестируйте CRM перед покупкой во время бесплатного " +
  "пробного периода. CRM-система - это не просто программа для учета клиентов. " +
  "Проверьте особенности оформления абонементов и правила списания занятий, вид и удобство расписания, " +
  "настройки автоматических действий, доступы для сотрудников, интеграции, отчеты и аналитику. Это то, что вы точно " +
  "не должны вести вручную. </p><p>И последнее - сравните стоимость подписки у разных СRM-систем для детского и учебного " +
  "центра. Выясните, есть ли ограничения по пользователям, количеству клиентов или группам учеников.</p>"

const AccordionComponent = () => {
  const [tab, setTab] = useState(null)
  return <div className={`container ${styles.tabs}`}>
    <div className={styles.descr}>
      <h2 className={styles.heading}>Советы по внедрению CRM-системы в детских и учебных центрах</h2>
      <div className={styles.content} dangerouslySetInnerHTML={{__html: descr }}></div>
    </div>
    <h2 className={styles.heading}>FAQs</h2>
    {data.map(t =>
      <div key={t.name}
           className={`${styles.tab} ${t.name === (tab && tab.name) ? styles.open : ""}`}
           onClick={() => setTab(t !== tab ? t : null)}>
        <div className={styles.info}>
          <div className={styles.title}>{t.title}</div>
          <AnimateHeight duration={300} height={t.name === (tab && tab.name) ? "auto" : 0}>
            <div className={styles.content}>{t.content}</div>
          </AnimateHeight>
        </div>
        <div className={styles.chevron}>
          <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.05287 11.2318L0.435026 2.66346C0.367169 1.27492 1.01181 0.631447 2.33502 0.733047L9.90108 8.28535L17.4671 0.733047C18.7903 0.631447 19.435 1.27492 19.3671 2.66346L10.7832 11.2318C10.1386 11.8414 9.66358 11.8414 9.05287 11.2318Z"
              fill="currentColor"/>
          </svg>
        </div>
      </div>,
    )}
  </div>
}

export default AccordionComponent
